$(function () {
    $(function(){
        //lazy load module
        var lastScrollTop = -1;
        var range = 500;
        var $imgList = $(".section-cover").find("[data-pc-src]");
        var deviceType = getDeviceType();
        $(window).on('scroll',function(){
            var $window = $(window);
            var scrollTop = $window.scrollTop();
            range = vcui.detect.isMobile? 200 : 500;
            if(scrollTop >= lastScrollTop) {
                $imgList = $imgList.filter(function () {
                    if (scrollTop + range + $window.height() >= $(this).offset().top ) {
                        homeLazyLoad($(this));
                        return false;
                    }
                    return true;
                });
            }
            lastScrollTop = $(window).scrollTop();
        }).on('resizeend',function(){
            if(getDeviceType() != deviceType){
                //console.log('디바이스 사이즈 변환');
                deviceType = getDeviceType();
                $imgList = $(".section-cover").find("[data-pc-src]");
                $(window).trigger('scroll');
            }
        }).trigger('scroll');
        function getDeviceType(){
            return window.innerWidth >= 768 ? "pc" : "mo";
        }
        function homeLazyLoad(_$this){
            var src = _$this.data(deviceType + "-src");
            if ( _$this.attr("src") !== src ) {
                _$this.attr("src", src);
            }
            var videoPoster = _$this.data(deviceType + "-poster");
            if (videoPoster && _$this.attr("poster") !== videoPoster) {
                _$this.attr("poster", videoPoster);
            }
            _$this.css('visibility','visible');
        }
    }());

    var type = window.innerWidth > 767 ? "pc" : "mo"; // 디바이스 구분 변수 // BTOCSITE-58878

    var $context = !!$('[data-hash="home"]').length ? $('[data-hash="home"]') : $(document);

    var heroBanner = document.querySelector(".hero-banner");
    var ul = heroBanner.querySelector(".ui_wide_slider ul");
    if (heroBanner.classList.contains("is-random")) {
        for (var i = ul.children.length; i >= 0; i--) {
            ul.appendChild(ul.children[(Math.random() * i) | 0]);
        }
    }
    heroBanner.style.opacity = 1;
    
    var $heroBanner = $(heroBanner);

    $context
        .find(".ui_wide_slider")
        .vcCarousel({
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            pauseOnHover: false,
            pauseOnFocus: false,
            swipeToSlide: true,
            dotsSelector: ".ui_wideslider_dots",
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            touchThreshold: 100,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
        })
        .on("carouselbeforechange", function (e, slide, prev, next) {
            if ($(slide.$slides.get(prev)).find("video").length > 0) {
                vidoePause($(slide.$slides.get(prev)).find("video"), true); // 이전 비디오 정지
            }
        })
        .on("carouselafterchange", function (e, slide, idx) {

            var partnerType = digitalData.userInfo.partnerType;
            void 0;

            if("AFF"==partnerType || "AFC"==partnerType || "ENT"==partnerType){
                $('.custom-indi-wrap').attr('class','custom-indi-wrap')
                //$('.custom-indi-wrap').remove()
                //$('.new-hero-banner').find('.slide-content').after(affHeroTemplate);
            }else{
                $('.custom-indi-wrap').attr('class','custom-indi-wrap new-type')
                //$('.custom-indi-wrap').remove()
                //$('.new-hero-banner').find('.slide-content').after(heroTemplate);
            }
            if ($(slide.$slides.get(idx)).find("video").length > 0) {
                videoPlay($(slide.$slides.get(idx)).find("video")); // 현재 비디오 시작
            }

            setHeroBanner();
            sendDataLayer();
        })
        .on("carouselplay", function (e) {
            var carousel = $heroBanner.vcCarousel("instance", false);
            var $currentSlide = carousel.$slides.filter('.ui_carousel_current');

            // autoplay 조작으로 인한 재계산
            changeAutoplayTime($currentSlide.find("video"));
        });

    // <-- BTOCSITE-46109 [UI] 홈메인 배너 자동롤링 재생/일시정시버튼 미노출
    $heroBanner.on("click",".video-button", function () {
        // carousel 내에 interval 새로 발생하므로 남은 시간 재계산 필요
        changeAutoplayTime($(this).parent().siblings("video"));

        if ($(this).hasClass("pause")) {
            vidoePause($(this).parent().siblings("video"));
        } else {
            videoPlay($(this).parent().siblings("video"));
        }

        // 비디오 재생/정지 버튼 클릭 시 carousel 내에 focusin 상태에선 autoplay 정지상태가 되는데 이를 해지하기 위함
        // focusin 1회는 발생하므로 carousel 내에 autoplay 의 interval 은 재시작 됨
        $heroBanner.find(".video-button").blur();
    });

    $heroBanner.find("video").on("ended", function (e) {
        e.target.play(); // 무조건 반복
    });
    // BTOCSITE-46109 [UI] 홈메인 배너 자동롤링 재생/일시정시버튼 미노출 -->

    // BTOCSITE-48628 Start
    $heroBanner.on("click",".slide-content ul > li.ui_carousel_current a", function(e){
        if($(this).attr('target')=="inApp"){
        	if( vcui.detect.isMobileDevice && isApp()) {
                e.preventDefault();

                var currentUrl = location.origin + $(this).attr('href');
                if(vcui.detect.isIOS){
                    var jsonString = JSON.stringify({'command':'openInAppBrowser', 'url': currentUrl});
                    // , 'titlebar_show': 'Y'
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                } else {
                    android.openNewWebview(currentUrl);
                }
            }else{
            	$(this).attr('target','_self');
            }
        }
    });
    //스태틱 배너 inApp브라우저 연결
    $heroBanner.on("click",".main-banner a", function(e){
        if($(this).attr('target')=="inApp"){
        	if( vcui.detect.isMobileDevice && isApp()) {
                e.preventDefault();

                var currentUrl = location.origin + $(this).attr('href');
                if(vcui.detect.isIOS){
                    var jsonString = JSON.stringify({'command':'openInAppBrowser', 'url': currentUrl});
                    // , 'titlebar_show': 'Y'
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                } else {
                    android.openNewWebview(currentUrl);
                }
            }else{
            	$(this).attr('target','_self');
            }
        }
    });
    // BTOCSITE-48628 End
    /**
     * BTOCSITE-48702 히어로배너 자동롤링 관련 일시정지버튼
     * videoPlay, vidoePause, changeAutoplayTime 메소드 신규 추가
    */

    /**
     * video 대상을 플레이 상태로
     * @param {jQueryElement} $target video jq객체
     */
    function videoPlay($target) {
        if (!!$target.length) {
            var video = $target.get(0);

            video.play();
            $target.siblings(".video-button-wrap").find(".video-button").addClass("pause");
        }
    }

    /**
     * video 대상을 정지 상태로
     * @param {jQueryElement} $target video jq객체
     * @param {boolean} reset 정지 시 재생시점 초기화 유무
     */
    function vidoePause($target, reset) {
        if (!!$target.length) {
            var video = $target.get(0);

            video.pause();
            if (reset) video.currentTime = 0;
            $target.siblings(".video-button-wrap").find(".video-button").removeClass("pause");
        }
    }

    /**
     * video 대상의 남은 재생시간을 계산해 carousel li 리스트의 각각의 다른 autoplay 딜레이 시간 지정
     * @param {jQueryElement} $target video jq객체
     */
    function changeAutoplayTime($target) {
        var carousel = $heroBanner.vcCarousel("instance", false);

        if (!!$target.length) {
            var video = $target.get(0);

            if (!isNaN(video.currentTime) && !isNaN(video.duration)) {
                var restTime = (video.duration - video.currentTime) * 1000;

                carousel.$currentSlide.attr('data-autoplay-speed', restTime);
                // console.log("!!!!! 남은 시간", restTime);
                carousel.autoPlay();

            } else {
                $target.off("loadedmetadata");
                $target.on("loadedmetadata", function (e) {
                    var restTime = (e.target.duration - e.target.currentTime) * 1000;

                    carousel.$currentSlide.attr('data-autoplay-speed', restTime);
                    // console.log("!!!!! 남은 시간 loadedmetadata : ", restTime);
                    carousel.autoPlay();
                });
            }
        }
    }

    function setHeroBanner(isFirst) {
        var heroList = $(".main-renewal .hero-banner .slide-track > li");
        var heroListCurrent = $(".main-renewal .hero-banner .slide-track > li.ui_carousel_current");
        var heroListAct = heroList.siblings(".ui_carousel_current").index();
        var heroListLength = heroList.length;
        var custom = $(".main-renewal .custom-indi-wrap");
        var slideCurrent = custom.find(".slide-page .current");
        var slideCount = custom.find(".slide-page .count");

        if (heroListLength > 1) {
            custom.css("display", "flex");
            slideCurrent.text(heroListAct);
            slideCount.text(heroListLength - 2);
        }

        // 영상 길이 계산
        changeAutoplayTime(heroListCurrent.find("video"));

        // window load, breakpoint 때 true // 자동롤링에 의한 호출 때 undefined
        if (isFirst === true) videoPlay(heroListCurrent.find("video")); // 첫 비디오 시작
    }

    function sendDataLayer() {
        var heroList = $(".main-renewal .hero-banner .slide-track > li");
        var heroListAct = heroList.siblings(".ui_carousel_current").index();
        var heroBannerTitle = heroList.siblings(".ui_carousel_current").find("a").data("title");

        /* BTOCSITE-36129 히어로 배너 dataLayer 추가 */
        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event': 'banner_impression',
                'customEventCategory': '배너노출',
                'customEventAction': '배너노출 - 메인 배너',
                'customEventLabel': '컨텐츠 : ' + heroBannerTitle + ' / #' + heroListAct
            });
        }
    }

    function spotCookie() {
        var spotCookie = lgkorUI.getCookie("isMainRenewalSpot");
        if (spotCookie === "false") {
            $(".main-spot").addClass("hide");
        } else {
            $(".main-spot").removeClass("hide");
        }
        $(".main-spot .main-spot__button").click(function (e) {
            lgkorUI.setCookie("isMainRenewalSpot", "false", false, 7);
            $(".main-spot").addClass("hide");
        });
    }
    spotCookie();

    function getModelDisplayAllCheck(data) {
        var count = 0;
        var display = true;

        for (var i = 0; i < data.length; i++) {
            if (data[i]["display"] == "disable") {
                count++;
            }
        }

        if (count == data.length) {
            display = false;
        }

        return display;
    }

    //세일즈 영역
    /* BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */
    // function salesCarousel() {
    //     var $section = $(".section--sales");
    //     var slideOpt = {
    //         infinite: false,
    //         autoplay: false,
    //         pauseOnHover: false,
    //         pauseOnFocus: false,
    //         swipeToSlide: true,
    //         slidesToShow: 4,
    //         slidesToScroll: 1,
    //         arrows: false,
    //         dots: true,
    //         touchThreshold: 100,
    //         cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
    //         speed: 150,
    //         responsive: [
    //             {
    //                 breakpoint: 10000,
    //                 settings: {
    //                     slidesToShow: 4,
    //                 },
    //             },
    //             {
    //                 breakpoint: 1280,
    //                 settings: {
    //                     slidesToShow: 3,
    //                 },
    //             },
    //             {
    //                 breakpoint: 768,
    //                 settings: "unslick",
    //             },
    //         ],
    //     };
    //     if (window.innerWidth > 767) {
    //         $section.find(".main-sales__list--type1").not(".slick-initialized").slick(slideOpt);
    //     } else {
    //         $section.find(".main-sales__list--type1").filter(".slick-initialized").slick("unslick");
    //     }
    // }
    /* //BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */

    function purchaseProbableProductList(){
    	var $section = $(".section--recomm");
    	var $recommendation = $section.find(".section__inner");
    	var header = $section.find(".section__header");
    	var $list = $section.find(".section__content").find(".main-sales__list");
        var ajaxUrl = $section.data("ajaxUrl");
        var title = "";
        var cnt = "";
        var subTitle = "";

        var listTempate =
        	"{{#each item in itemList}}" +
        	'<div class="main-sales__item" data-model-id="{{item.modelId}}">' +
        	'	<a href="{{item.modelUrlPath}}" class="item__inner" data-contents="{{item.sku}}/{{item.modelDisplayNameForGA}}/{{item.scenarioNo}}/{{item.order}}/{{item.cnt}}">' +
        	'		<div class="item__image" aria-hidden="true">' +
        	'			<span><img src="{{item.mediumImageUrl}}" alt=""></span>'+
            '           {{#if item.lgecomOnlyFlag == "Y"}}' +
            '               <em class="badge lge-only"><span class="blind">닷컴 ONLY</span></em>'+
            '           {{/if}}' +
        	'		</div>' +
        	'		<div class="item__infos">' +
        	'			<div class="item__name">' +
            '               <span class="main-title">' +
            '                   <span class="main">{{#raw item.modelDisplayName}}</span>' +
            '					<span class="sub-text-new">{{#raw item.onlySpecInfo}}</span>' +
            '               </span>' +
            '           </div>' +
        	'			<div class="item__sku">{{item.modelName}}</div>' +
            '			<div class="item__price-wrap">' +
            '               {{#if partnerType == "LGE"}}' +
            '                   {{#if item.empMaxBenefitPrice > 0 && item.empMaxBenefitPrice < item.lgeSellPrice}}' +
            '			            <span class="item__price-title">최대혜택가</span>' +
            '			            <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.empMaxBenefitPrice)}}</em>원</span>' +
            '                   {{#elsif item.empMaxBenefitPrice <= 0 && item.lgeSellPrice > 0 && item.lgeSellPrice < item.obsSellingPrice}}' +
            '	    		            <span class="item__price-title">임직원할인가</span>' +
            '		    	            <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.lgeSellPrice)}}</em>원</span>' +
            '                   {{#else}}' +
            '                       {{#if item.maxBenefitPrice > 0 && item.maxBenefitPrice < item.obsSellingPrice}}' +
            '	    		            <span class="item__price-title">최대혜택가</span>' +
            '		    	            <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.maxBenefitPrice)}}</em>원</span>' +
            '                       {{#else}}' +
            '                           <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.maxBenefitPrice)}}</em>원</span>' +
            '                       {{/if}}' +
            '                   {{/if}}' +
            '               {{#else}}' +
            '                   {{#if item.maxBenefitPrice > 0 && item.maxBenefitPrice < item.obsSellingPrice}}' +
            '			            <span class="item__price-title">최대혜택가</span>' +
            '			            <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.maxBenefitPrice)}}</em>원</span>' +
            '                   {{#else}}' +
            '			            <span class="item__price item__price--current"><em>{{vcui.number.addComma(item.obsSellingPrice)}}</em>원</span>' +
            '                   {{/if}}' +
            '               {{/if}}' +
            '			</div>' +
        	'		</div>' +
        	'	</a>' +
        	'</div>' +
        	"{{/each}}";

        lgkorUI.requestAjaxDataPost(
	        ajaxUrl,
	        null,
	        function(result){
                // BTOCSITE-51870 [전시] LG트윈스 우승기념 - 윈윈페스티벌 대응
                if (vcui.isEmpty(result)) {
                    $recommendation.hide();
                    return;
                }

	        	if(result.RTN_CD == "S" && result.DATA_CNT > 3){
	        		var recommendationList = result.MODEL;
	        		title = result.TITLE;
	        		if (recommendationList.length > 0) {
                        recommendationList.forEach(function (item, idx) {
                            item.currentPrice = vcui.number.addComma(item.obsMasterPrice);
                            item.modelDisplayNameForGA = item.modelDisplayName.replace(/(<([^>]+)>)/ig,"");
                            item.scenarioNo = result.scenarioNo;
                            item.order = idx + 1;
                            item.cnt = result.DATA_CNT;
                        });
                        // var htmlContents = vcui.template(self.listTempate, { itemList: products });
   	             	 	header.append('<div class="section__title">'+title+'</div>');
                        $list.empty().append(vcui.template(listTempate, { itemList: recommendationList, partnerType: digitalData.userInfo.partnerType}));
                        $recommendation.show();

	   	             	var modelList = $('.section--recomm').find('.main-sales__list').find('.item__inner');
		   	            var sku = '';
		   	            for(var i = 0; i < modelList.length; i++){
		   	            	if(i == 0){
		   	            		sku += modelList.eq(i).attr('data-contents').split('/')[modelList.eq(i).attr('data-contents').split('/').length-3]
		   	            		+ '/' + modelList.eq(i).attr('data-contents').split('/')[0];
		   	             	}else{
		   	             		sku += '/' + modelList.eq(i).attr('data-contents').split('/')[0];
		   	             	}
		   	             }
		   	             	void 0;
		   	            /*if (typeof dataLayer !== 'undefined' && dataLayer) {
		   	            	dataLayer.push({
		   	                     'event' : 'customEvent',
		   	                 	'ga4event' : 'banner_impression',
		   	                 	'customEventCategory': '배너노출',
		   	                     'customEventAction': '메인 배너 노출 - 개인화 추천',
		   	                     'customEventLabel': '{' + sku + '}'
		   	                });
		   	                console.log("### purchaseprobable datalayer push");
		   	            }*/
                     }
	        	}else if(result.RTN_CD == "S" && result.DATA_CNT < 4){
	        		$recommendation.hide();
	        		var scenario = result.scenarioNo;
	        		void 0;
	        		if (typeof dataLayer !== 'undefined' && dataLayer) {
	                    dataLayer.push({
	                        'event' : 'customEvent',
	                    	'ga4event' : 'banner_impression',
	                    	'customEventCategory': '배너노출',
	                        'customEventAction': '메인 배너 노출 - 개인화 추천',
	                        'customEventLabel': scenario
	                    });
	                    void 0;
	                }
	        	}else{
	        		$recommendation.hide();
	        	}
	        }, true, true);
    }
    purchaseProbableProductList();

    var isVisible = false;

    function sendPurchaseProbableDataLayer() {
        var modelList = $('.section--recomm').find('.main-sales__list').find('.item__inner');
        var sku = '';
        for(var i = 0; i < modelList.length; i++){
        	if(i == 0){
        		sku += modelList.eq(i).attr('data-contents').split('/')[modelList.eq(i).attr('data-contents').split('/').length-3]
        		+ '/' + modelList.eq(i).attr('data-contents').split('/')[0];
        	}else{
        		sku += '/' + modelList.eq(i).attr('data-contents').split('/')[0];
        	}
        }
        	void 0;
        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event' : 'customEvent',
            	'ga4event' : 'banner_impression',
            	'customEventCategory': '배너노출',
                'customEventAction': '메인 배너 노출 - 개인화 추천',
                'customEventLabel': sku
            });
            void 0;
        }
    }

    function checkVisible() {
    	var viewportHeight = $(window).height();
    	var scrollTop = $(window).scrollTop();
    	var y = $('.section--recomm').offset().top;
    	var elementHeight = $('.section--recomm').height();
    	return ((y+elementHeight < (viewportHeight + scrollTop)) && (y >scrollTop));
    }

    function checkViewPurchaseProbable() {
    	if(!isVisible && checkVisible() && $('.section--recomm').find('.section__inner').css("display")=="block"){
    		sendPurchaseProbableDataLayer();
    		isVisible = true;
    	}
    	isVisible && window.removeEventListener('scroll',checkViewPurchaseProbable);
    }

    window.addEventListener('scroll', checkViewPurchaseProbable);

    function sendScrollPurchaseProbableDataLayer(){
    	if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event' : 'customEvent',
            	'ga4event' : '',
            	'customEventCategory': '클릭',
                'customEventAction': '메인 개인화 추천 영역 - 스크롤 클릭',
                'customEventLabel': '클릭 컨텐츠 : 스크롤 이동'
            });
            void 0;
        }
    }

    var checkYn = true;

    $('.section--recomm').find('.main-sales__list--type1').scroll(function(){
    	if(checkYn){
    		sendScrollPurchaseProbableDataLayer();
    		checkYn = false;
    	}
    });


    function requestSalesProduct() {
        var $section = $(".section--sales");
        var lgecomOnlyFlagHtml = '<em class="badge lge-only"><span class="blind">닷컴 ONLY</span></em>';

        $section.each(function () {
            var $this = $(this);
            var $list = $this.find(".main-sales__list");
            var $item = $list.find(".main-sales__item");
            var cardTypeFlag = $this.find(".main-sales__list--type2").length > 0;
            var sectionVisibleFlag = false;

            var ajaxUrl = $this.data("ajaxUrl");
            var modelArray = [];
            var partnerType = '';

            $item.each(function () {
                if ($(this).data("modelId") != undefined && $(this).data("modelId") != "") {
                    modelArray.push($(this).data("modelId").trim());
                }
                if (digitalData && digitalData.userInfo && digitalData.userInfo.partnerType) {
                    partnerType = digitalData.userInfo.partnerType;
                    if(partnerType != 'LGE')
                        partnerType = 'B2C';
                }
            });

            lgkorUI.requestAjaxData(ajaxUrl, { id: modelArray.toString(), partnerType : partnerType }, function (result) {
                if (result.status == "success") {
                    var listData = result.data;

                    if (listData.length > 0) {
                        listData.forEach(function (item, index) {
                            var $target = $this.find('[data-model-id="' + item.modelId + '"]');
                            if (item.display == "disable" && !$this.hasClass("is--test")) {
                                $target.remove();
                                return;
                            }
                            item.currentPrice = vcui.comma(item.price);
                            item.ogPrice = vcui.comma(item.obsOriginalPrice);
                            $target.find("a").attr("href", item.modelUrlPath);
                            $target.not(".main-sales__item--banner").find(".item__image img").attr("src", item.mediumImageUrl);

                            var titleHtml = '<span class="main-title">' +
                            					'<span class="main">' + item.modelDisplayName + '</span>' +
                            					'<span class="sub-text-new">' + (item.onlySpecInfo || '') + '</span>' +
                        					'</span>';
                            $target.find(".item__name").html(titleHtml);
                            $target.find(".item__sku").text(item.modelName);
                            $target.find(".item__model-name").text(item.sku);

                            if (item.lgecomOnlyFlag == 'Y') {
                                $target.find(".item__image").append(lgecomOnlyFlagHtml);
                            }

                            if (item.display == "soldout") {
                                $target.addClass("is--soldout");
                            }
                            if (item.obsSellFlag == "N") {
                                $target.find(".item__price-wrap").css({ opacity: 0 });
                                $target.find(".item__price--current").html("&nbsp;");
                            } else {
                                if(partnerType == 'LGE'){
                                    if(item.empMaxBenefitPrice > 0 && item.empMaxBenefitPrice < item.lgeSellPrice){
                                        $target.find(".item__price-wrap").prepend('<span class="item__price-title">최대혜택가</span>')
                                        $target.find(".item__price--current em").text(vcui.comma(item.empMaxBenefitPrice));
                                    }else if (!item.empMaxBenefitPrice > 0 && item.lgeSellPrice > 0 && item.lgeSellPrice < item.obsSellingPrice){
                                        $target.find(".item__price-wrap").prepend('<span class="item__price-title">임직원할인가</span>')
                                        $target.find(".item__price--current em").text(vcui.comma(item.lgeSellPrice));
                                    }else if (item.maxBenefitPrice > 0 && item.maxBenefitPrice < item.obsSellingPrice){
                                        $target.find(".item__price-wrap").prepend('<span class="item__price-title">최대혜택가</span>')
                                        $target.find(".item__price--current em").text(vcui.comma(item.maxBenefitPrice));
                                    }else{
                                        $target.find(".item__price--current em").text(vcui.comma(item.obsSellingPrice));
                                    }
                                }else{
                                    if(item.maxBenefitPrice > 0 && item.maxBenefitPrice < item.obsSellingPrice){
                                        $target.find(".item__price-wrap").prepend('<span class="item__price-title">최대혜택가</span>')
                                        $target.find(".item__price--current em").text(vcui.comma(item.maxBenefitPrice));
                                    }else{
                                        $target.find(".item__price--current em").text(vcui.comma(item.obsSellingPrice));
                                    }
                                }
                                // $target.find(".item__price--current em").text(item.currentPrice);
                            }
                        });

                        if (!$this.hasClass("is--test")) {
                            var enableList = listData.filter(function (item) {
                                return item.display != "disable";
                            });
                            if (
                                getModelDisplayAllCheck(listData) ||
                                (!cardTypeFlag && enableList.length >= 4) ||
                                (cardTypeFlag && enableList.length >= 3)
                            ) {
                                sectionVisibleFlag = true;
                            }
                        } else {
                            sectionVisibleFlag = true;
                        }

                        if (cardTypeFlag && $this.find(".main-sales__list--type2 .main-sales__item--banner").length == 0) {
                            $this.find(".main-sales__list--type2").addClass("no-banner");
                        }
                        if (cardTypeFlag) {
                            $list.find(".main-sales__item").eq(2).nextAll().remove();
                        } else {
                            $list.find(".main-sales__item").eq(19).nextAll().remove();
                            //salesCarousel(); //BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건
                        }
                        if (sectionVisibleFlag) $this.addClass("is-active");
                    } else {
                        $this.hide().removeClass("is-active");
                    }
                } else {
                    $this.hide().removeClass("is-active");
                }
            });
        });
    }
    requestSalesProduct();

    var isfirstModuleVisible = false;

    function sendFirstModuleDataLayer() {
        var modelList = $('.section--sales').find('.main-sales__list--type1').find('.item__model-name');
        var sku = '';
        for(var i = 0; i < modelList.length; i++){
        	if(i == 0){
        		sku += modelList[0].innerHTML;
        	}else{
        		sku += '/' + modelList[i].innerHTML;
        	}
        }
        	void 0;
        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event' : 'customEvent',
            	'ga4event' : 'banner_impression',
            	'customEventCategory': '배너노출',
                'customEventAction': '배너 노출 - 수동 추천 영역',
                'customEventLabel': sku
            });
            void 0;
        }
    }

    function checkFirstModuleVisible() {
    	var viewportHeight = $(window).height();
    	var scrollTop = $(window).scrollTop();
        if (!$('.section--sales').eq(0).length) return;
    	var y = $('.section--sales').eq(0).offset().top;
    	var style = $('.section--sales').eq(0).attr('style');
        var elementHeight = $('.section--sales').eq(0).height();
        $('.section--sales').eq(0).attr('style',style);
    	return ((y+elementHeight < (viewportHeight + scrollTop)) && (y >scrollTop));
    }

    function checkViewFirstModule() {
    	if(!isfirstModuleVisible && checkFirstModuleVisible()){
    		sendFirstModuleDataLayer();
    		isfirstModuleVisible = true;
    	}
    	isfirstModuleVisible && window.removeEventListener('scroll',checkViewFirstModule);
    }

    window.addEventListener('scroll', checkViewFirstModule);


    function requestStoryList() {
        var $story = $(".main-story__contents");
        var $list = $story.find(".main-story__list");
        var ajaxUrl = $story.data("ajaxUrl");
        var storyId = $story.data("id");
        var param = {};

        param.id = storyId.trim();

        var listTempate =
            "{{#each item in itemList}}" +
            '<div class="main-story__item">' +
            '    <a href="{{item.storyUrl}}" class="item__link">' +
            '        <div class="item__image">' +
            '            <img src="{{item.largeImage}}" alt="{{item.largeImageAlt}}" />' +
            "        </div>" +
            '        <div class="item__text-wrap">' +
            '            <span class="item__category">{{item.contentsName}}</span>' +
            '            <p class="item__title">{{item.title}}</p>' +
            "        </div>" +
            "    </a>" +
            "</div>" +
            "{{/each}}";

        lgkorUI.requestAjaxDataFailCheck(
            ajaxUrl,
            param,
            function (result) {
                if (result.status == "success") {
                    var storyList = result.data;
                    // console.log('!!!!! success requestStoryList', storyList);
                    if (storyList.length > 0) {
                        $list.empty().append(vcui.template(listTempate, { itemList: storyList }));
                        storyCarousel();
                    } else {
                        $story.hide();
                    }
                } else {
                    $story.hide();
                }
            },
            function () {
                $story.hide();
            }
        );
    }
    requestStoryList();

    function storyCarousel() {
        // console.log('!!!!! storyCarousel');

        var mainStorySlide = $(".main-story__list");
        var mainStorySlideLength = mainStorySlide.find(".main-story__item").length;
        var storyOpt = {
            infinite: false,
            autoplay: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            swipeToSlide: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            touchThreshold: 100,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            responsive: [
                {
                    breakpoint: 10000,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: "unslick",
                },
            ],
        };
        $(".main-story__contents").not(".is-active").addClass("is-active");

        if (mainStorySlide.is(".slick-initialized")) mainStorySlide.slick("unslick");

        if (mainStorySlideLength > 1 && type == "pc") {
            mainStorySlide.slick(storyOpt);
        }
    }

    // BTOCSITE-58055 홈메인 제휴사 복지몰 띠배너 수정 건 // empYn 값이 필요하여 loginInfo ajax 후 초기화 실행
    function mainTopBanner(empYn) {
        var mainTopBannerSlide = $(".main-banner-swiper .banner__list");

        if (empYn === 'N') {
            mainTopBannerSlide.find('#employee-banner').remove();
        } else if (empYn === 'Y') {
            mainTopBannerSlide.find('#employee-banner').show();
        }

        var mainTopBannerSlideLength = mainTopBannerSlide.find(".slide-item").length;
        var mainTopBannerSlideOption = {
            infinite: true,
            variableWidth: false,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            touchThreshold: 100,
        };

        if (mainTopBannerSlide.is(".slick-initialized")) mainTopBannerSlide.slick('unslick');

        if (mainTopBannerSlideLength > 1) {
            mainTopBannerSlide.slick(mainTopBannerSlideOption);
        } else if (mainTopBannerSlideLength < 1) {
            mainTopBannerSlide.closest(".section").remove();
        }
    }

    function targetLink(target) {
        var $target = $(target);

        if (isApp()) {
            // 앱 케이스
            if (vcui.detect.isIOS) {
                var jsonString = JSON.stringify({ command: "openLinkOut", url: target.href });
                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
            } else {
                android.openLinkOut(target.href);
            }
        } else {
            // 일반 브라우져
            window.open(target.href);
        }
    }

    function sliderSet() {
        var mainProductSlide = $(".main-product__slide");
        var mainProductSlideLength = mainProductSlide.find(".slide-item").length;
        var mainProductSlideOption = {
            infinite: true,
            variableWidth: false,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            touchThreshold: 100,
        };

        var mainPromotionBannerSlide = $(".main-promotion__banner .banner__list");
        var mainPromotionBannerSlideLength = mainPromotionBannerSlide.find(".slide-item").length;
        var mainPromotionBannerSlideOption = {
            infinite: true,
            variableWidth: false,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            touchThreshold: 100,
        };

        var infoSliderNum = $(".info-area").find(".slide-item").length;
        var infoOpt = {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            vertical: true,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            pauseOnFocus: true,
        };

        var mainThemeSlide = $(".main-theme__slide");
        var mainThemeSlideLength = mainThemeSlide.find(".main-theme__item").length;
        var themeOpt = {
            infinite: false,
            autoplay: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            swipeToSlide: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            touchThreshold: 100,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            responsive: [
                {
                    breakpoint: 10000,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: "unslick",
                },
            ],
        };
        // BTOCSITE-24361 메인 최신제품소식 배너수 수정 start
        /* BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */
        var newProductSlide = $(".main-newproduct__list");
        var newProductSlideLength = newProductSlide.find(".main-newproduct__item").length;
        // var newProductOpt = {
        //     infinite: false,
        //     autoplay: false,
        //     pauseOnHover: false,
        //     pauseOnFocus: false,
        //     slidesToShow: 3,
        //     slidesToScroll: 3,
        //     arrows: false,
        //     touchThreshold: 100,
        //     cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
        //     speed: 150,
        //     dots: true,
        //     responsive: [
        //         {
        //             breakpoint: 1280,
        //             settings: {
        //                 arrows: false,
        //             },
        //         },
        //         {
        //             breakpoint: 768,
        //             settings: "unslick",
        //         },
        //     ],
        // };
        /* //BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */
        // BTOCSITE-24361 메인 최신제품소식 배너수 수정 end

        mainProductSlide.parent(".main-product__module").css("width", "100%");
        if (mainProductSlideLength > 1) {
            mainProductSlide.not(".slick-initialized").slick(mainProductSlideOption);
        }

        if (mainPromotionBannerSlideLength > 1) {
            mainPromotionBannerSlide.not(".slick-initialized").slick(mainPromotionBannerSlideOption);
        } else if (mainPromotionBannerSlideLength < 1) {
            mainPromotionBannerSlide.closest(".section").remove();
        }

        if (mainThemeSlideLength > 1 && type == "pc") {
            mainThemeSlide.not(".slick-initialized").slick(themeOpt);
        } else {
            mainThemeSlide.filter(".slick-initialized").slick("unslick");
        }
        // BTOCSITE-24361 메인 최신제품소식 배너수 수정 start
        // if (newProductSlideLength > 2) {
        //     if (newProductSlideLength % 3 !== 0) {
        //         while (newProductSlide.find(".main-newproduct__item").length % 3 !== 0) {
        //             newProductSlide.append('<div class="main-newproduct__item"></div>');
        //         }
        //     }
        //     newProductSlide.not(".slick-initialized").slick(newProductOpt);
        // } else {
        //     newProductSlide.closest(".section__inner").remove();
        // }
        // BTOCSITE-24361 메인 최신제품소식 배너수 수정 end
        //BTOCSITE-30212
        $(window).on('resizeend',function(){
            if($(this).width() > 768){
                if (newProductSlideLength > 2) {
                    /* BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */
                    // if (newProductSlideLength % 3 !== 0) {
                    //     while (newProductSlide.find(".main-newproduct__item").length % 3 !== 0) {
                    //         newProductSlide.append('<div class="main-newproduct__item dummy"></div>');
                    //     }
                    // }
                    //newProductSlide.not(".slick-initialized").slick(newProductOpt);
                    /* //BTOCSITE-34648 LGE.COM 내 메인페이지 'LG 베스트 가전 추천 제품' 상품 탭 구좌 개수 변경 요청의 건 */
                    $('.wide-section.section--newproduct .section__content').css('visibility','visible');
                } else {
                    newProductSlide.closest(".section__inner").remove();
                }
            }else{
                $('.main-newproduct__item.dummy').remove();
                $('.wide-section.section--newproduct .section__content').css('visibility','visible');
            }
        }).trigger('resizeend');
        ////BTOCSITE-30212
        var $infoArea =  $(".info-area");
        $infoArea.on("init", function (event, slick) {
            var $page = $(".info-section .slide-page");
            var $current = $page.find(".current");
            var $count = $page.find(".count");

            $current.text(slick.currentSlide + 1);
            $count.text(slick.$slides.length);
        }).on("beforeChange", function (event, slick, currentSlide, nextSlide) {
            var $page = $(".info-section .slide-page");
            var $current = $page.find(".current");

            $current.text(nextSlide + 1);
        }).on("click", ".slick-arrow", function () {
            if (infoOpt.autoplay && $(".info-area-wrap").find(".btn-info-play.pause").length > 0) {
                $infoArea.slick("slickPause");
                setTimeout(function () {
                    $infoArea.slick("slickPlay");
                }, 100);
            } else {
                $infoArea.slick("slickPause");
            }
        });
        if ($infoArea.hasClass("slick-slider")) {
            $infoArea.not(".slick-initialized").slick(infoOpt);
        } else {
            if (infoSliderNum == 0) {
                $(".info-section").hide();
            } else if (infoSliderNum == 1) {
                $(".info-area-wrap").addClass("info-solo");
            } else if (infoSliderNum > 1) {
                $infoArea.slick(infoOpt);
            }
        }
    }

    // 타임딜 슬라이더 // BTOCSITE-58878 ajax done 후 실행을 위해 sliderSet() 에서 분리
    function timeDealSlider() {
        // console.log('!!!!! timeDealSlider');

        // BTOCSITE-56741 타임딜 홈메인 디자인 변경 적용 건
        var timeDealSlide = $(".main-timedeal__list");
        var timeDealSlideLength = timeDealSlide.find(".slide-item").length;
        var timeDealSlideOption = {
            infinite: true,
            variableWidth: false,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
            speed: 150,
            touchThreshold: 100,
        };

        if (timeDealSlide.is(".slick-initialized")) timeDealSlide.slick("unslick");

        if (timeDealSlideLength > 1 && type == "mo") {
            timeDealSlide.slick(timeDealSlideOption);
        }
    }


        // console.log('!!!!! require');

        var isApplication = isApp();

        $context.find(".container").css({ overflow: "visible", height: "auto" });

        refreshSlides(); // slide 관련 초기화 // BTOCSITE-58878

        // 기존 resize형 breakpoint 제거 후 breakpoint 이벤트로 변경 // BTOCSITE-58878
        var breakpoint = window.matchMedia("(min-width: 767px)");
        breakpoint.addEventListener('change', breakpointChecker);

        $(document).on("click", "a", function (e) {
            var href = $(e.currentTarget).attr("href").replace(/ /gi, "");
            if (href === "#") {
                e.preventDefault();
            }
        });

        $(".section").on("click", "[data-modal]", function (e) {
            e.preventDefault();
            var $modal = $($(this).data("modal"));
            $modal.vcModal({ opener: $(this) });
        });

        $(".main-quick-menu__list a, .main-theme__item a, .main-promotion__list a, .main-newproduct__item a").on("click", function (e) {
            if (this.target == "_blank") {
                e.preventDefault();
                targetLink(this);
            }
        });

        // 재생/멈춤 클릭이벤트 위치 수정
        $(".btn-info-play").on("click", function () {
            var $this = $(this);
            if ($this.hasClass("pause")) {
                $this.removeClass("pause");
                $(".info-area").slick("slickPause");
                $(".btn-info-play span").text("재생");
            } else {
                $this.addClass("pause");
                $(".info-area").slick("slickPlay");
                $(".btn-info-play span").text("멈춤");
            }
        });

        if (isApplication) {
            $("header").find(".header-bottom").addClass("app-btm");
        }

        /* BTOCSITE-33562 [개인맞춤서비스] 구매가망고객 추천 - 홈메인 개발 */
        $('.main-message-bar__title button').on('click', function(){
            var $this = $(this);
            var $msgBar = $('.main-message-bar__box');
            $msgBar.toggleClass('on');
            if( $msgBar.hasClass('on') ){
                $this.attr('aria-expanded', 'true');
                $this.find('span').text('내용 닫기');
            } else{
                $this.attr('aria-expanded', 'false');
                $this.find('span').text('내용 더 보기');
            }
        });
        /* //BTOCSITE-33562 [개인맞춤서비스] 구매가망고객 추천 - 홈메인 개발 */


    // breakpoint 함수 // BTOCSITE-58878
    function breakpointChecker(e) {
        var isDesktop = e ? e.matches : window.innerWidth > 767;
        type = isDesktop ? 'pc' : 'mo';

        refreshSlides();
    }

    // slide 형 ui 갱신 통합 함수 // BTOCSITE-58878
    function refreshSlides() {
        sliderSet();
        timeDealSlider();
        storyCarousel();
        setHeroBanner(true);
    }

    //BTOCSITE-27191
    window.addEventListener('pagehide',function(e){
        var index = $('.main-ranking__tab .tabs li.on').index();
        sessionStorage.setItem('bestTabIndex',index);

    });
    window.addEventListener('pageshow', function(e){
        if (e.persisted || (window.performance && window.performance.navigation.type == 2) || e.originalEvent && e.originalEvent.persisted) {

           var index = Number(sessionStorage.getItem('bestTabIndex'));
           $('.main-ranking__tab .tabs li').eq(index).children('a').trigger('click');
        }
    });
    ////BTOCSITE-27191

    $(window).load(function(){
        sendDataLayer();

        //BTOCSITE-35717 베스트랭킹 개편
        //setNewBestRank('CT50000046');
        //setNewBestRank(); //퍼블용 호출(개발에서는 요소 append후 호출)
        //bestRanking.setRecentViewTab();
        //////BTOCSITE-35717 베스트랭킹 개편
    });
    //BTOCSITE-35717 베스트랭킹 개편
    function setNewBestRank(_prevCid){
        var prevCid = _prevCid; //히스토리 data-category-id
        var $best = $('.main-bestranking__tab .ui_stickyTab');
        var $bestLi = $('.main-bestranking__tab .ui_stickyTab li');
        var $bestSlider = $('.main-bestranking__content.ui_carousel_1_slider');
        var leftArr = [];
        var firstLeft = 0
        var isSelecting = false;
        for(var i=0; i< $bestLi.length; i++){
            if(i == 0){
                firstLeft = $bestLi.eq(i).offset().left;
            }
            leftArr[i] = $bestLi.eq(i).offset().left - firstLeft;
        }
        var idx = 0;
        if(prevCid){
            for(var i=0; i< $bestLi.length; i++){
                if($bestLi.eq(i).find('a').attr('data-category-id') === prevCid){
                    idx = i;
                }
            }
        }

        $('.arrow-btn .prev').on('click',function(){
            var left  = Math.abs($best.vcSmoothScroll('getPosition').x);
            var idx = -1;
            for(var i=$bestLi.length-1; i >= 0; i--){
                if(leftArr[i] < left && idx == -1){
                    idx = i;
                }
            }
            $best.vcSmoothScroll('prevPage');
        });
        $('.arrow-btn .next').on('click',function(){
            var left  = Math.abs($best.vcSmoothScroll('getPosition').x);
            var idx = -1;
            for(var i=0; i< $bestLi.length; i++){
                if(leftArr[i] > left && idx == -1){
                    idx = i;
                }
            }
            $best.vcSmoothScroll('nextPage');
        });
        $best.on('smoothscrollend', function(e,data){
            activeBtn();
            if(data.isStart){
                $('.arrow-btn .prev').hide();
            }else if(data.isEnd){
                $('.arrow-btn .next').hide();
            }
        });
        $(window).on("resize", function () {
            activeBtn();
            if($best.vcSmoothScroll('getPosition').x == 0){
                $('.arrow-btn .prev').hide();
            }else if(Math.abs($best.vcSmoothScroll('getPosition').x) >= Math.abs($best.vcSmoothScroll('getMaxScrollX'))){
                $('.arrow-btn .next').hide();
            }
        }).on("resizeend.bestranking", function (e) {
            //if(e.namespace != 'bestranking'){return;}
            $best.vcSmoothScroll("scrollToActive");
            //PC 터치 스와이프 방지
            if(window.innerWidth >= 768){
                $('.main-bestranking__content').vcCarousel('instance').options.swipe = false;
            }else{
                $('.main-bestranking__content').vcCarousel('instance').options.swipe = false;
            }
            ////PC 터치 스와이프 방지
        })
        $best.on('stickyTabchange',function(e,_obj){
			if( $(this).data('isSessionCategoryId') ){
				$(this).data('isSessionCategoryId',false)
				return;
			}
            select(_obj.selectedIndex);
        });
        $bestSlider.on('carouselafterchange', function (e, instance) {
            if( $(this).data('isSessionCategoryId') ){
				$(this).data('isSessionCategoryId',false)
				return;
			}
            select(instance.currentSlide);
        });
        function init(){
            if(!$best.attr('ui-modules')){
                $best.vcStickyTab();
                //console.log('vcStickyTab 없음 모듈세팅시작');
            }
            if(!$bestSlider.attr('ui-modules')){
                $bestSlider.vcCarousel();
                //console.log('vcCarousel 없음 모듈세팅시작');
            }
            setTimeout(function(){
                $best.vcStickyTab('update');
                var bestCarousel = $('.main-bestranking__content').vcCarousel('instance');
                if (bestCarousel) {
                    bestCarousel.setOption({initialSlide: idx})
                    bestCarousel.reinit();
                    bestCarousel.refresh();
                }

                $(window).trigger('resize').trigger('resizeend.bestranking');
                var isFirstSlide = true;
                var prevCurrentSlide = 0;
                $('.main-bestranking__content').on('carouselafterchange', function (e, instance) {
                    $('.main-bestranking__content .main-bestranking__item').removeAttr('style');
                    if( instance.$currentSlide.find('.main-bestranking__item').length <= 4 ){
                        $('.main-bestranking__content .slide-content').css('height','652px');
                    }else{
                        $('.main-bestranking__content .slide-content').css('height','auto');
                    }
                    if(instance.$currentSlide.data('isMoreList')){
                        instance.$currentSlide.find('.main-bestranking__item').show();
                        instance.$currentSlide.find('.btn_more').hide();
                    }
                    if(!isFirstSlide){
                        prevCurrentSlide != instance.currentSlide && $('html, body').animate({scrollTop:$('#bestRanking_section').offset().top-40},300);
                    }
                    prevCurrentSlide = instance.currentSlide;
					isFirstSlide = false
                }).on('carouselbeforechange', function (e, instance) {
                    //$('.main-bestranking__content .btn_more').removeAttr('style');
                    //$('.main-bestranking__content .main-bestranking__item').removeAttr('style')
                });

                var salesHtml = $('.section.wide-section.section--sales').find('.main-sales__list.main-sales__list--type2').html();
                if(salesHtml.trim()==''){ $('.main-sales__list.main-sales__list--type2').closest('.section--sales').attr('style','display:none !important') }
                $('.main-bestranking__content').on('click','.btn_more',function(){
                    $('.main-bestranking__content .btn_more').hide();
                    $('.main-bestranking__content .ui_carousel_slide').data('isMoreList',true);
                    $('.main-bestranking__content .main-bestranking__item').show();
                    bestRanking.variable.isMoreBtn = true;
                });

            });
            // S : BTOCSITE-59364 [구독 Task] 홈에 구독 구좌 투입
            $('.ui_smooth_scrolltab').vcSmoothScrollTab('update');
            // E : BTOCSITE-59364 [구독 Task] 홈에 구독 구좌 투입
        }
        function activeBtn(){
            if($best.width() < $best.find('.tabs').width()){
                $('.arrow-btn .next').show();
                $('.arrow-btn .prev').show();
            }else{
                $('.arrow-btn .next').hide();
                $('.arrow-btn .prev').hide();
            }
        }
        var preSelectIdx = -1;
        function select(_idx){
            if(isSelecting == true || preSelectIdx == _idx){return;}
            isSelecting = true;
            $best.vcStickyTab('select', _idx);
            $best.vcSmoothScroll("scrollToActive"); // [BTOCSITE-69175] [모니터링] 홈메인 베스트랭킹영역 카테고리 탭
            $bestSlider.vcCarousel('instance').slideHandler(_idx, false, true);
            $(window).trigger('resize').trigger('resizeend.bestranking');
            preSelectIdx = _idx;
            // 베스트랭킹 GA 호출
            if ( bestRanking.variable.isVisible) {
                bestRanking.rankingSendDataLayer();
            }
            $('#bestRanking_section .slide-content.ui_carousel_list').css('height','auto');
            setTimeout(function(){isSelecting = false;},100);
        }
        init();
    }

    //BTOCSITE-48628 Start
    var partnerBanner = {
	        variable: {
	        },
	        setting : function() {
	            var self=this;
	            self.$heroBannerSection = $(".hero-banner");
	            self.heroTemplate =     '{{#each (item, index) in itemList}}' +
	                                		'{{#if item.displayFlag == "Y" }}' +
	                                		'<li class="slide-conts ui_carousel_slide is-left {{#if item.displayContentType== "MOVIE"}}is-video{{/if}} {{#if item.mainTitleRgb== "FFFFFF"}}text-white{{/if}}">' +
	                                			'{{#if item.displayContentUrl != "" && item.displayContentUrl != null }}' +
	                                			'<a href="{{item.displayContentUrl}}" target ="{{item.displayContentTarget}}" class="full-link" data-title="{{item.heroTitle}}" data-contents="{{item.heroTitle}} / Slot {{index+1}}">&nbsp;</a>' +
	                                			'{{/if}}' +
	                                			'<div class="slide-img">' +
	                                				'{{#if item.displayContentType == "MOVIE" }}' +
	                                					'{{#if mobileFlag == "Y" }}' +
	                                					'<video data-mo-src="{{item.mobileVideoPath}}"' +
	                                						'data-pc-src="{{item.pcVideoPath}}"' +
	                                						'src = "{{item.mobileVideoPath}}"' +
	                                						'data-mo-poster="{{item.mobileVideoImgPath}}"' +
	                                						'data-pc-poster="{{item.pcVideoImgPath}}"' +
	                                						'poster = "{{item.mobileVideoImgPath}}"' +
	                                						'width="100%"' +
	                                						'height="100%"' +
	                                						'type="video/mp4"' +
	                                						'playsinline muted>' +
	                                					'</video>' +
	                                					'{{#else}}' +
	                                					'<video data-mo-src="{{item.mobileVideoPath}}"' +
	                                						'data-pc-src="{{item.pcVideoPath}}"' +
	                                						'src = "{{item.pcVideoPath}}"' +
	                                						'data-mo-poster="{{item.mobileVideoImgPath}}"' +
	                                						'data-pc-poster="{{item.pcVideoImgPath}}"' +
	                                						'poster = "{{item.pcVideoImgPath}}"' +
	                                						'width="100%"' +
	                                						'height="100%"' +
	                                						'type="video/mp4"' +
	                                						'playsinline muted>' +
	                                					'</video>' +
	                                					'{{/if}}' +
	                                					'<div class="mobile-dimmed"></div>' +
	                                					'<div class="video-button-wrap">' +
	                                						'<button class="video-button"></button>' +
	                                					'</div>' +
	                                				'{{#else}}' +
	                                					'{{#if mobileFlag == "Y" }}' +
	                                					'<img alt="{{item.imgAlt}}" src="{{item.mobileImgPath}}" data-pc-src="{{item.pcImgPath}}" data-mo-src="{{item.mobileImgPath}}">' +
	                                					'{{#else}}' +
	                                					'<img alt="{{item.imgAlt}}" src="{{item.pcImgPath}}" data-pc-src="{{item.pcImgPath}}" data-mo-src="{{item.mobileImgPath}}">' +
	                                					'{{/if}}' +
	                                					'<div class="mobile-dimmed"></div>' +
	                                				'{{/if}}' +
	                                			'</div>' +
	                                			'<div class="slide-info-area">' +
	                                				'<div class="boxs">' +
	                                					'<div class="brand-logo" {{#if item.brandImage == null || item.brandImage == ""}} style="display:none" {{/if}} >' +
	                                						'<img src="{{item.brandImage}}" alt="{{item.brandImageAlt}}">' +
	                                					'</div>' +
	                                					'<p class="txt" {{#if item.mainTitleRgb!=null && item.mainTitleRgb!="" && item.mainTitleRgb!="FFFFFF"}} style="color:#{{item.mainTitleRgb}};" {{/if}}>' +
	                                						'{{#if item.mainTitle != null && item.mainTitle != "" }}' +
	                                						'{{#raw item.mainTitle}}' +
	                                						'{{/if}}' +
	                                					'</p>' +
	                                					'<p class="small" {{#if item.subTitleRgb!=null && item.subTitleRgb!=""}} style="color:#{{item.subTitleRgb}};" {{/if}}>' +
	                                						'{{#if item.subTitle != "" && item.subTitle != null }}' +
	                                						'{{#raw item.subTitle}}' +
	                                						'{{/if}}' +
	                                					'</p>' +
	                                					'<div class="link-wrap">' +
	                                					'</div>' +
	                                				'</div>' +
	                                			'</div>' +
	                                		'</li>' +
	                                		'{{/if}}' +
	                                	'{{/each}}';
	            self.staticTemplate = '{{#each (item, index) in staticList}}'+
	                                      '{{#if item.displayFlag=="Y"}}'+
	                                      '<div class="banner__item slide-item">'+
	                                        '<a href="{{item.displayContentUrl}}" target="{{item.displayContentTarget}}" class="main-banner__link"  data-contents="{{item.bannerTitle}}">'+
	                                            '<div class="main-banner__image">'+
	                                                '{{#if mobileFlag == "Y" }}'+
	                                                    '<img alt="{{item.imgAlt}}"'+
	                                                        'src="{{item.mobileImgPath}}"'+
	                                                        'data-pc-src="{{item.pcImgPath}}"'+
	                                                        'data-mo-src="{{item.mobileImgPath}}"'+
	                                                '/>'+
	                                                '{{#else}}'+
	                                                    '<img alt="{{item.imgAlt}}"'+
	                                                        'src="{{item.pcImgPath}}"'+
	                                                        'data-pc-src="{{item.pcImgPath}}"'+
	                                                        'data-mo-src="{{item.mobileImgPath}}"'+
	                                                    '/>'+
	                                                '{{/if}}'+
	                                            '</div>'+
	                                            '<div class="main-banner__text-wrap {{#if item.mainTitleRgb == "FFFFFF"}}text-white{{/if}}">'+
	                                                '<div class="main-banner__title" {{#if item.mainTitleRgb!=null && item.mainTitleRgb!="" && item.mainTitleRgb!="FFFFFF"}} style="color:#{{item.mainTitleRgb}};" {{/if}}>{{#raw item.mainTitle}}</div>'+
	                                                '<div class="main-banner__text"><p>{{item.btnTitle}}</p></div>'+
	                                            '</div>'+
	                                        '</a>'+
	                                      '</div>'+
	                                      '{{/if}}'+
	                                  '{{/each}}';
	        },
	        getLoginInfo: function() {
	        	var self = this;

              // BTOCSITE-64387 공통(7/7) memberinfo, logininfo api 개선
              self.variable.isLogin = digitalData.userInfo.isLogin;
              self.variable.partnerType = digitalData.userInfo.partnerType;
              self.variable.partnerCode = digitalData.userInfo.partnerCode;
              self.variable.partnerName = digitalData.userInfo.partnerName;
              self.variable.empYn = digitalData.userInfo.empYn; 
	        },
	        init : function(){
	            var self = this;
	            self.setting();

	            this.getLoginInfo();
	            var param={};
	            param.partnerType=this.variable.partnerType;
	            param.partnerCode=this.variable.partnerCode;

                var empYn = this.variable.empYn; // BTOCSITE-58055 홈메인 제휴사 복지몰 띠배너 수정 건

                if("AFF"==param.partnerType || "AFC"==param.partnerType){
	                lgkorUI.requestAjaxDataPost("/mkt/api/display/retrieveHeroBanner.lgajax",param, function (result) {
	                    if(result.data.displayHeroBannerList != null && result.data.displayHeroBannerList.length >0){
	                    	//기존 herobanner 요소 리스트로 저장
	                    	var commonHeroList = $('.hero-banner .slide-content ul > li');

	                    	//구좌에서 로드한 데이터로 li 요소 생성
	                    	var heroTemplateTmp = vcui.template(self.heroTemplate, {itemList: result.data.displayHeroBannerList, mobileFlag:result.data.isMobile});

                            var heroBannerList = result.data.displayHeroBannerList;

	                    	var yFlagLen = 0;
	                    	heroBannerList.forEach(function(hero) {
	                    	    if(hero.displayFlag=="Y"){
	                    	        yFlagLen=yFlagLen+1;
	                    	    }
	                    	});


	                    	// cloned 가 없는 요소만 붙이기
	                    	commonHeroList.each(function(idx, item){
	                    		if(!$(item).hasClass('ui_carousel_cloned')){
                                    var dataContents = $(item).children('a').attr('data-contents');
                                    var slotIdx = dataContents.indexOf('Slot');
                                    var slotNum = dataContents.slice(slotIdx+5);
                                    var sliceDataContents = dataContents.slice(0,slotIdx+4);
                                    var changeNum = Number(slotNum)+ yFlagLen;
                                    var changeContents = sliceDataContents+' '+changeNum;

                                    $(item).children('a').attr('data-contents',changeContents);

	                    			heroTemplateTmp+=item.outerHTML;
	                    		}
	                    	});


	                    	// 기존 히어로배너 정보 삭제
	                    	$('.hero-banner .slide-content ul > li').remove();

	                    	// 신규 히어로 배너로 저장
	                    	$('#common_hero_section .slide-track').prepend(heroTemplateTmp);
	                    	// carousel 새로고침
	                    	$('.hero-banner').vcCarousel('instance').reinit();
	                        $('.hero-banner').vcCarousel('instance').refresh();
	                        heroLiveShowPip();

                            //무한재생 이벤트리스터 재선언
	                        $('.hero-banner').find("video").on("ended", function (e) {
                                e.target.play(); // 무조건 반복
                            });

	                    } else {
	                        heroLiveShowPip();
	                    }
	                    if(result.data.displayStaticBannerList != null && result.data.displayStaticBannerList.length >0){
	                    	var staticTemplateTmp = vcui.template(self.staticTemplate, {staticList: result.data.displayStaticBannerList, mobileFlag:result.data.isMobile});
	                    	$('.main-banner-swiper').find('.banner__list').prepend(staticTemplateTmp);
	                    	//$('.main-quick-menu').after(staticTemplateTmp);
	                    }

                        mainTopBanner(empYn); // BTOCSITE-58055
	                }, null, null, function () {
                        // fail
                        heroLiveShowPip();
                        mainTopBanner(empYn); // BTOCSITE-58055
                    });
	            } else {
	                heroLiveShowPip();
                    mainTopBanner(empYn); // BTOCSITE-58055
                }
	        }
	    };
	    partnerBanner.init();
    //BTOCSITE-48628 End

    var bestRanking = {
        variable: {
            isVisible: false,
            isMoreBtn : false,
        },
        setting : function() {
            var self = this;
            self.$wrapper = $(".section--ranking");
            self.$tabsWrap = $(".tabs-wrap-new");
            self.categoryTempate = '{{#each (item, index) in itemList}}' +
                                    '<li>' +
                                        '<a href="#" data-category-id="{{item.targetId}}" data-category-name="{{item.targetName}}">' +
                                            '<span class="thumb"><img src="/kr/images/BRK/img_BestRanking_{{item.targetId}}_tab.png" alt="{{item.targetName}}"></span>' +
                                            '<span class="sort">{{item.targetName}}</span><em class="blind">선택됨</em>' +
                                        '</a>' +
                                    '</li>' +
                                    '{{/each}}';

            self.seasonThemeTempate =   '{{#each (item, index) in itemList}}' +
                                            '{{#if item.mainTitleUrl == "subscribe" }}' +
                                                '<li class="line">' +
                                            '{{#else}}' +
                                                '<li>' +
                                            '{{/if}}' +
                                                '<a href="/best-ranking/{{item.mainTitleUrl}}" data-is-link="true">' +
                                                    '<span class="gateway" style="background:#{{item.backgroundRgb}}">{{item.addInfo1}}</span>' +
                                                '</a>' +
                                            '</li>' +
                                        '{{/each}}';
        },
        init : function() {
            var self = this;
            var empYn = digitalData.userInfo.empYn;
            lgkorUI.requestAjaxDataPost("/mkt/api/bestRanking/retrieveHomeTodayRanking.lgajax", {}, function (result) {
                    $(".section--ranking").eq(1).attr("style", "display:none !important");
                    // BTOCSITE-51870 [전시] LG트윈스 우승기념 - 윈윈페스티벌 대응
                    if (!vcui.isEmpty(result.data[0])) {
                        // 베스트랭킹 활성화
                        // $("#bestRanking_section").show();
                        // $("#bestRanking_section").css("visibility", "visible");

                        self.setting();

                        var categoryTemplate = vcui.template(self.categoryTempate, {itemList: result.data[0].categoryList});
                        self.$tabsWrap.find("ul").append(categoryTemplate);
                        var seasonThemeTemplate = vcui.template(self.seasonThemeTempate, {itemList: result.data[0].seasonThemeList});
                        self.$tabsWrap.find("ul").append(seasonThemeTemplate);

						//init catgoryId
						var categoryid= $('#bestRankingTab li').eq(0).find('a').data('category-id');
						var sessionItem = sessionStorage.getItem("recent_view_pdp_info");

                        var $bestLi = $('.main-bestranking__tab .ui_stickyTab li');

						//session pdp category check
						if (!vcui.isEmpty(sessionItem)) {
						    var sessionCategoryId;
			                var item = JSON.parse(sessionItem);
			                if (vcui.isEmpty(item.categoryId)) {
			                    lgkorUI.requestAjaxDataPost("/mkt/api/bestRanking/retrieveCategoryId.lgajax", {modelId : item.modelId}, function (result) {
			                        sessionCategoryId = result.data[0].categoryId;
			                    });
			                } else {
			                    sessionCategoryId = item.categoryId
			                }

                            var idx = 0;
                            if(sessionCategoryId){
                                for(var i=0; i< $bestLi.length; i++){
                                    if($bestLi.eq(i).find('a').attr('data-category-id') === sessionCategoryId){
                                        $('.main-bestranking__tab .ui_stickyTab').data('isSessionCategoryId',true);
                                        $('.main-bestranking__content.ui_carousel_1_slider').data('isSessionCategoryId',true);
                                        categoryid = sessionCategoryId;
                                        $bestLi.removeClass('on');
                                        $bestLi.eq(i).addClass('on');
                                    }
                                }
                            }
						}
						//category product call
                        self.retrieveBestRanking(categoryid);

						//categoryTab click event
						$bestLi.off("click").on('click',function(){
							//dup click check
							var currentCategory;
							for(var i=0; i< $bestLi.length; i++){
				                if($bestLi.eq(i).hasClass('on')){
        				             currentCategory=$bestLi.eq(i).find('a').data('category-id');
				                }
				            }
			                var categoryId = $(this).find('a').data('category-id');
							if(currentCategory != categoryId){
								self.retrieveBestRanking(categoryId);
							}
			            });

                    }
            });

            $('.section--ranking .section__link').on('click',function(e){
                var bestRankingIndex = $('.main-bestranking__tab .tabs li.on').index();
                sessionStorage.setItem('bestRankingTabIndex',bestRankingIndex);
            });

            $('.main-bestranking__tab .ui_stickyTab').on('stickyTabchange',function(e,_obj){
                // 베스트 랭킹 더보기버튼 url 파라미터 변경
                var categoryId = $('.main-bestranking__tab .tabs li.on').find("a").data("category-id") || "";
                var categoryName = $('.main-bestranking__tab .tabs li.on').find("a").data("category-name") || "";
                if (!vcui.isEmpty(categoryId) && !vcui.isEmpty(categoryName)) {
                    $("#bestRanking_section .section__link").attr("href", "/best-ranking/todays");
                    $("#bestRanking_section .section__link").attr("data-contents", categoryName);
                }
            });

        },

        //BTOCSITE-67109 마젠토(obs)다운타임 시 영향도 페이지 대응 - downTimeYn 조건 추가
		retrieveBestRanking : function(categoryid) {
		    var deferred = $.Deferred();
			productTempate =  '<div class="slide-conts ui_carousel_slide">' +
				'<ol class="main-bestranking__list">' +
				'{{#each (product, index) in itemList}}' +
					'<li class="main-bestranking__item" data-ec-product="{{product.ecProductInfo}}" data-imp-event="microPlpOpen">' +
						'<a href="{{product.modelUrlPath}}" class="item__link" data-contents="{{product.gaDataContents}}">' +
							'<div class="item__badge">' +
								'<div class="prod_badge">' +
								'{{#if product.timeDealBadgeFlag == "Y" }}' +
									'<span class="badge_box time">타임딜</span>' +
								'{{/if}}' +
								'{{#if product.releaseBadgeFlag}}' +
									'<span class="badge_box release-date">{{product.releaseBadgeFlag}}</span>' +
								'{{/if}}' +
								'{{#if product.mltpPlcyBadgeFlag == "Y" }}' +
									'<span class="badge_box multi-item">다품목할인</span>' +
								'{{/if}}' +
								'</div>' +
							'</div>' +
							'<div class="item__image">' +
								'<img src="{{product.mediumImageAddr}}" alt="{{product.modelDisplayName}}">' +
								'{{#if product.lgecomOnlyFlag == "Y"}}' +
									'<em class="badge lge-only"><span class="blind">닷컴 ONLY</span></em>' +
								'{{/if}}' +
							'</div>' +
							'<div class="item__info">' +
								'<div class="item__top">' +
									'<div class="item__name">' +
										'<span class="main-title">' +
											'<span class="main">{{#raw product.modelDisplayName}}</span>' +
											'<span class="sub-text-new">' +
												'{{#if product.keywdNmA}}<em class="sub">{{product.keywdNmA}}</em>{{/if}}' +
												'{{#if product.keywdNmB}}<em class="sub">{{product.keywdNmB}}</em>{{/if}}' +
											'</span>' +
										'</span>' +
									'</div>' +
									'<span class="item__sku">{{product.modelName}}</span>' +
								'</div>' +
								'<div class = "item__bottom">' +
									'<div class="item__price-wrap">' +
										'{{#if product.memberDiscountRate && product.memberDiscountRate >= 5}}' +
											'<span class="text-discount-rate">{{product.memberDiscountRate}}%</span>' +
										'{{/if}}' +
										'<span class="item__price item__price--current">' +
											'<strong class="item__number">{{vcui.number.addComma(product.obsSellingPrice)}}</strong>원' +
										'</span>' +
									'</div>' +
                                    '{{#if empYn=="Y" && product.lgeSellPrice > 0 && product.lgeSellPrice < product.obsSellingPrice}}' +
                                        '<div class="item__price-emp-wrap">' +
                                            '<span class="title">임직원할인가</span>' +
                                            '<span class="contents"><strong class="price">{{vcui.number.addComma(product.lgeSellPrice)}}</strong>원</span>' +
                                        '</div>' +
                                        '{{#if downTimeYn != "Y"}}' +
                                            '{{#if product.empMaxBenefitPrice > 0 && product.empMaxBenefitPrice < product.lgeSellPrice}}' +
                                                '<div class="item__price-benefits-wrap">' +
                                                    '<span class="title">최대혜택가</span><span class ="contents"><strong class="price">{{vcui.number.addComma(product.empMaxBenefitPrice)}}</strong>원</span>' +
                                                '</div>' +
                                            '{{/if}}' +
                                        '{{/if}}' +
                                    '{{#else}}' +
                                        '{{#if downTimeYn != "Y"}}' +
                                            '{{#if product.maxBenefitPrice < product.obsSellingPrice}}' +
                                                '<div class="item__price-benefits-wrap">' +
                                                    '<span class="title">최대혜택가</span><span class ="contents"><strong class="price">{{vcui.number.addComma(product.maxBenefitPrice)}}</strong>원</span>' +
                                                '</div>' +
                                            '{{/if}}' +
                                        '{{/if}}' +
                                    '{{/if}}' +
								'</div>' +
							'</div>' +
						'</a>' +
					'</li>' +
				'{{/each}}' +
				'</ol>' +
			'</div>'

			var empYn = digitalData.userInfo.empYn;
			var categoryId=categoryid;
			var sendUrl = "/mkt/api/bestRanking/retrieveCategoryProductList.lgajax";
			var rankingType = "todays"
			var param={};
			param.rankingType = rankingType;
			param.categoryId = categoryId;
			param.categoryIds = '';
			param.keyword = '';
			param.mltpModelYn = 'N';
			param.timeModelYn = 'N';
			param.tmrrModelYn = 'N';
			lgkorUI.requestAjaxData(sendUrl, param, function(result) {
			    if (result.data[0].bestRankingProductList != null && result.data[0].bestRankingProductList.length > 0) {
					itemList = result.data[0].bestRankingProductList;
					if(result.data[0].bestRankingProductList.length > 10){
						itemList = itemList.slice(0,10);
					}
			        var productListTemplate = vcui.template(productTempate, {itemList:itemList ,empYn:empYn, downTimeYn:result.data[0].downTimeYn});
			        $(".main-bestranking__content").find(".slide-track").html(productListTemplate);

					//퍼블 함수 호출
					var $bsetTab = $('.main-bestranking__tab .ui_stickyTab')
                    $bsetTab.data('isOnce') || setNewBestRank();
                    $bsetTab.data('isOnce',true);

                    var categoryLength = $('.section--ranking').find('.main-bestranking__list').length;
                    for(var i = 0; i < categoryLength; i++){
                        if($('.section--ranking').find('.main-bestranking__list').eq(i).find('.main-bestranking__item').length > 5){
                            $('.section--ranking').find('.main-bestranking__list').eq(i).append('<button type="button" class="btn_more">6-10 위 더보기</button>')
                        }
                    }

					if(bestRanking.variable.isMoreBtn){
		                $('.main-bestranking__content .btn_more').hide();
		                $('.main-bestranking__content .main-bestranking__item').show();
		            }
                    //BTOCSITE-44878
                    $('.item__badge').each(function(){
                        if($(this).height() == 0){
                            $(this).parent().addClass('no_badge');
                        }
                    });
                    deferred.resolve();
					$("#bestRanking_section").show();
                    $("#bestRanking_section").css("visibility", "visible");
			    }
			}, "POST", "json");
			return deferred.promise();
		},

        setRecentViewTab : function() {
            var sessionItem = sessionStorage.getItem("recent_view_pdp_info");
            if (!vcui.isEmpty(sessionItem)) {
                var item = JSON.parse(sessionItem);
                if (vcui.isEmpty(item.categoryId)) {
                    lgkorUI.requestAjaxDataPost("/mkt/api/bestRanking/retrieveCategoryId.lgajax", {modelId : item.modelId}, function (result) {
                        setNewBestRank(result.data[0].categoryId);
                    });
                } else {
                    setNewBestRank(item.categoryId);
                }
            } else {
                setNewBestRank();
            }
        },

        rankingBannerSendDataLayer : function() {

            var categoryName = "";
            categoryName = $("#bestRankingTab").find("li.on").find(".sort").text();

            if (typeof dataLayer !== 'undefined' && dataLayer) {
                dataLayer.push({
                    'event': 'customEvent',
                    'ga4event': 'banner_impression',
                    'customEventCategory': '배너노출',
                    'customEventAction': '배너노출 - 베스트랭킹',
                    'customEventLabel': categoryName
                });
            }
        },

        rankingSendDataLayer : function() {
            $(".main-bestranking__item").removeAttr("data-imp-event");
            var idx = $('.main-bestranking__tab .tabs li.on').index();
            $(".main-bestranking__content .slide-conts").eq(idx).find("li").attr("data-imp-event", "microPlpOpen");

            if (typeof dataLayer !== 'undefined' && dataLayer) {
                dataLayer.push({
                    'event': 'microPlpOpen'
                });
            }
        },

        checkViewBestRanking : function() {
            if ( !bestRanking.variable.isVisible && bestRanking.checkVisible() && $("#bestRankingTab").find("li.on").length > 0) {
                bestRanking.rankingBannerSendDataLayer();
                bestRanking.variable.isVisible = true;
            }

            bestRanking.variable.isVisible && window.removeEventListener('scroll', bestRanking.checkViewBestRanking);
        },

        checkVisible : function() {
            var viewportHeight = $(window).height(); // Viewport Height
            var scrolltop = $(window).scrollTop(); // Scroll Top
            if (!$(".section--ranking").eq(0).find(".section__inner").length) return;
            var y = $(".section--ranking").eq(0).find(".section__inner").offset().top + $(".section--ranking").eq(0).find(".section__inner").height();

            // 화면에 안보여도 요소가 위에만 있으면 (페이지를 로드할때 스크롤이 밑으로 내려가 요소를 지나쳐 버릴경우)
            return ((y < (viewportHeight + scrolltop)));
        }

    };
    bestRanking.init();
    ////BTOCSITE-35717 베스트랭킹 개편

    // 스크롤 이벤트 등록
    window.addEventListener('scroll', bestRanking.checkViewBestRanking);


    // BTOCSITE-49045 [전시] 딜 플랫폼
    function timeDealProductList() {
        var $timeDealSection = $(".section--timedeal");
        var $sectionTitle = $(".section--timedeal .section__title");
        var $list = $(".main-timedeal__list");
        var $sectionLink = $(".section--timedeal .section__link-wrap");

        //BTOCSITE-67109 마젠토(obs)다운타임 시 영향도 페이지 대응 - downTimeYn 조건 추가
        var productTemplate =
            '{{#each product in productList}}' +
                '<div class="main-timedeal__item slide-item">' +
                    '<a href="{{product.modelUrlPath}}" class="item__inner" data-contents="타임딜" data-modelId="{{product.modelId}}" data-sku="{{product.sku}}" data-remainDay="{{product.remainDay}}" data-endDate="{{product.endDate}}">' +
                        '<div class="item__cont">' +
                            '<div class="item__infos">' +
                                '<div class="item__name">' +
                                    '<span class="main-title">' +
                                        '<span class="main">' +
                                            '{{#if product.extraText != ""}}' +
                                                '{{#raw product.extraText}}' +
                                        '</span>' +
                                            '{{#else}}' +
                                                '{{#raw product.modelDisplayName}}' +
                                        '</span>' +
                                        '<span class="sub-text-new">{{#raw product.onlySpecInfo}}</span>' +
                                            '{{/if}}' +
                                    '</span>' +
                                '</div>' +
                                '<div class="item__sku">{{product.modelName}}</div>' +
                                '<div class="item__price-wrap">' +
                                    '{{#if downTimeYn == "Y"}}'+
                                        '<div class="item__price--tit">타임딜 할인가</div>' +
                                        '<em class="blind">할인 후 판매가격</em>' +
                                        '<span class="item__price item__price--current"><em>{{vcui.comma(product.obsSellingPrice)}}</em>원</span>' +
                                    '{{#else}}' +
                                        '{{#if product.maxBenefitPrice < product.obsSellingPrice}}' +
                                            '<div class="item__price--tit">최대혜택가</div>' +
                                            '<em class="blind">할인 후 판매가격</em>' +
                                            '<span class="item__price item__price--current"><em>{{vcui.comma(product.maxBenefitPrice)}}</em>원</span>' +
                                        '{{#else}}' +
                                            '<div class="item__price--tit">타임딜 할인가</div>' +
                                            '<em class="blind">할인 후 판매가격</em>' +
                                            '<span class="item__price item__price--current"><em>{{vcui.comma(product.obsSellingPrice)}}</em>원</span>' +
                                        '{{/if}}' +
                                    '{{/if}}' +
                                '</div>' +
                            '</div>' +
                            '<div class="item__image" aria-hidden="true">' +
                                '{{#if product.lgecomOnlyFlag == "Y"}}' +
                                    '<span class="badge-only"><span class="blind">닷컴ONLY</span></span>' +
                                '{{/if}}' +
                                '<span><img src="{{product.mediumImageAddr}}" alt="{{#raw product.modelDisplayName}}"></span>' +
                            '</div>' +
                        '</div>' +
                        '<div class="item__btm">' +
                            '{{#if product.remainDay > 0}}' +
                                '<span class="tit"><span>타임딜</span><em>D-{{product.remainDay}}</em></span>' +    // BTOCSITE-65927 타임딜 페이지 상품 유닛 개선
                            '{{#else}}' +
                                '<span class="tit"><span>타임딜</span> </span>' +   // BTOCSITE-65927 타임딜 페이지 상품 유닛 개선
                            '{{/if}}' +
                            '<span class="desc"><em>{{product.dealInventoryQty}}</em>개 남음</span>' +
                        '</div>' +
                    '</a>' +
                '</div>' +
            '{{/each}}';

        lgkorUI.requestAjaxDataPost("/homeMain/timeDealProduct.lgajax", {}, function (result) {
            //console.log('!!!!! success timeDealProduct', result.productList);
            if (!vcui.isEmpty(result.productList)) {
                if (!vcui.isEmpty(result.mainTitleUrl)) {
                    $sectionTitle.html("<a href='"+result.mainTitleUrl+"'>"+result.mainTitle+"</a>");
                } else {
                    $sectionTitle.html(result.mainTitle);
                }

                $sectionLink.find("a").attr("href", result.extraTitleUrl);
                $sectionLink.find("a").text(result.extraTitle);

                $list.empty().append(vcui.template(productTemplate, { productList: result.productList, mainTitle: result.mainTitle, downTimeYn: result.downTimeYn}));
                $timeDealSection.show();

                timeDealSlider();

                $(".main-timedeal__item").find("a").each(function() {
                   if (vcui.isEmpty($(this).data("remainday"))) {
                       setCountDown(this, $(this).data("enddate"));
                   }
                });
            } else {
                window.removeEventListener('scroll', checkViewTimeDeal);
            }
       });
    }
    timeDealProductList();

    var timeDealisVisible = false;

    function setCountDown(obj, endDate) {
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = new Date(endDate) - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }

            var timer = hours + ":" + minutes + ":" + seconds;
            if (distance < 0) {
                clearInterval(x);
                void 0;
            } else {
                $(obj).find(".tit").html("<span>타임딜</span><em>" + timer + "<em>");        // BTOCSITE-65927 타임딜 페이지 상품 유닛 개선 
            }
        }, 1000);
    }

    function timeDealSendDataLayer() {
        var sku = "";
        $(".main-timedeal__item").each(function() {
            sku += $(this).find("a").data("sku") + " / ";
        });
        sku = sku.trim().slice(0, -1);

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event': 'customEvent',
                'ga4event': 'banner_impression',
                'customEventCategory': '배너노출',
                'customEventAction': '배너노출 - 타임딜',
                'customEventLabel': sku.trim()
            });
        }
    }

    function checkViewTimeDeal() {
        if ($(".section--timedeal").length > 0) {
            if ( !timeDealisVisible && checkVisibleTimeDeal()) {
                timeDealSendDataLayer();
                timeDealisVisible = true;
            }
            timeDealisVisible && window.removeEventListener('scroll', checkViewTimeDeal);
        } else {
            window.removeEventListener('scroll', checkViewTimeDeal);
        }
    }

    function checkVisibleTimeDeal() {
        var viewportHeight = $(window).height(); // Viewport Height
        var scrolltop = $(window).scrollTop(); // Scroll Top
        var y = $(".section--timedeal").offset().top + $(".section--timedeal").height();

        return ((y < (viewportHeight + scrolltop)));
    }

    window.addEventListener('scroll', checkViewTimeDeal);

    // BTOCSITE-50568 엘라쇼 PIP 적용
    function liveShowPip() {
        $(".main-banner").find(".banner__item").each(function(){
            var liveShowUrl = $(this).find("a").attr("href");
            if (!vcui.isEmpty(liveShowUrl) && liveShowUrl.indexOf("/livecommerce/") > -1) {
                // benefits 으로 시작하면 라이브 방송 url
                if (liveShowUrl.indexOf("/benefits/") > -1 || liveShowUrl.indexOf("/highlight") > -1) {
                    var $liveShowAnchor = $(this).find("a");
                    $liveShowAnchor.attr("href", "javascript:void(0)");
                    $liveShowAnchor.on("click", function () {
                        liveShowUrl = crossDomainGl.getCrossDomainGlIncludeUrl(liveShowUrl);
                        if (isApp()) {
                            if (liveShowUrl.indexOf("http://") == -1 && liveShowUrl.indexOf("https://") == -1) {
                                liveShowUrl = "https://" + window.location.host + liveShowUrl;
                            }
                            if (vcui.detect.isIOS) {
                                var jsonString = JSON.stringify({
                                    url: liveShowUrl,
                                    command: "startLiveCommerce",
                                });
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                            } else {
                                android.startLiveCommerce(liveShowUrl);
                            }
                        } else {
                        	window.sessionStorage.setItem("liveUrl", liveShowUrl);
                            location.href = liveShowUrl;
                        }
                    });
                }
            }
        });
    }
    liveShowPip();
    //// BTOCSITE-50568 엘라쇼 PIP 적용

    // BTOCSITE-66074 - App 홈 > 히어로배너 접근시 PIP 적용
    function heroLiveShowPip() {
        $(".hero-banner").find(".slide-conts").each(function(){
            var liveShowUrl = $(this).find("a").attr("href");
            if (!vcui.isEmpty(liveShowUrl) && liveShowUrl.indexOf("/livecommerce/") > -1) {
                // benefits 으로 시작하면 라이브 방송 url
                if (liveShowUrl.indexOf("/benefits/") > -1 || liveShowUrl.indexOf("/highlight") > -1) {
                    var $liveShowAnchor = $(this).find("a");
                    $liveShowAnchor.attr("href", "javascript:void(0)");
                    $liveShowAnchor.on("click", function () {
                        liveShowUrl = crossDomainGl.getCrossDomainGlIncludeUrl(liveShowUrl);
                        if (isApp()) {
                            if (liveShowUrl.indexOf("http://") == -1 && liveShowUrl.indexOf("https://") == -1) {
                                liveShowUrl = "https://" + window.location.host + liveShowUrl;
                            }
                            if (vcui.detect.isIOS) {
                                var jsonString = JSON.stringify({
                                    url: liveShowUrl,
                                    command: "startLiveCommerce",
                                });
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                            } else {
                                android.startLiveCommerce(liveShowUrl);
                            }
                        } else {
                            window.sessionStorage.setItem("liveUrl", liveShowUrl);
                            location.href = liveShowUrl;
                        }
                    });
                }
            }
        });
    }

    //BTOCSITE-48943 [개인맞춤서비스] 홈메인 개인화된 메시지 노출 건
    var $chatbot = $('.btn-floating-wrap .KRP0005.floating-menu');
    var $btnTop = $('.KRP0004.floating-menu');
    var $msgWrap = $('.messagebar-wrap');
    var $floatingWrap = $('.floating-wrap');
    var $chatbotWrap = $('.btn-floating-wrap');
    var pcMsgOriH = 40;
    var moMsgOriH = 32;

    $(window).on('scroll',function(){
        setScrollMsgBar();
       
    }).on('resize',function(){
        setScrollMsgBar();
    })

    // X 버튼 영역 클릭
    $msgWrap.find('.btn-close').on('click',function(e){
        e.preventDefault();
        saveMessageBarData("C");
        $(window).trigger('closeMsgBar');
    });

    // 메시지 영역 클릭
    $msgWrap.find('a.tit').on('click',function(e){
        e.preventDefault();
        saveMessageBarData("R");
    });

    function setScrollMsgBar(){
        if( window.innerWidth >767 ){
            //PC
            if( $msgWrap.hasClass('on') ){
                //개인화 메시지 열림시
                if($(window).scrollTop() <= 0){
                    $chatbot.css({'bottom':'106px'});
                }else{
                    $chatbot.css({'bottom':'172px'});
                }
                $btnTop.find('.back-to-top').css({'position':'relative','top':'-58px'});
            }else{
                $chatbot.removeAttr('style');
                $btnTop.find('.back-to-top').removeAttr('style');
            }
        }else{
            //MO
            if($msgWrap.hasClass('on')){
                var h = 0
                if($msgWrap.height() > moMsgOriH){
                    h = $msgWrap.height() - moMsgOriH
                }
                var oriH  = h;
                //개인화 메시지 열림시
                if(vcui.detect.isMobileDevice){
                    h = (8 + h) * -1
                }else{
                    h = (78 + h) * -1
                }
                if($(window).scrollTop() <= 0){
                    $chatbot.css({'bottom':102+oriH});
                }else{
                    $chatbot.css({'bottom':150+oriH});
                }
                $btnTop.find('.back-to-top').css({'position':'relative','top':h});
            }else{
                $chatbot.removeAttr('style');
                $btnTop.find('.back-to-top').removeAttr('style');
            }
        }
        if($msgWrap.hasClass('on')){
            $floatingWrap.hasClass('onMsgBar') || $floatingWrap.addClass('onMsgBar');
            $chatbotWrap.hasClass('onMsgBar') || $chatbotWrap.addClass('onMsgBar');
        }else{
            $floatingWrap.hasClass('onMsgBar') && $floatingWrap.removeClass('onMsgBar');
            $chatbotWrap.hasClass('onMsgBar') && $chatbotWrap.removeClass('onMsgBar');
        }
    }

    setScrollMsgBar();

    //외부 호출용
    $(window).on('openMsgBar',function(){
        $msgWrap.addClass('on');
        setScrollMsgBar();
    });

    $(window).on('closeMsgBar',function(){
        $msgWrap.removeClass('on');
        setScrollMsgBar();
        $(window).trigger('activeChatbot');
    });

    checkMessageBarData();

    //메시지 바 데이터 노출 조건 조회
    function checkMessageBarData(){
        var $wrap = $('.messagebar-wrap');
        var $box = $('.messagebar-box');
        var ajaxUrl = $wrap.data("retrieve-url");

        lgkorUI.requestAjaxDataPost(
            ajaxUrl,
            null,
            function(result){
                $msgWrap.removeClass('on');
                setScrollMsgBar();

                $box.find('a.tit').html("");

                var percent = "5";

                var memberStringList = [
                    "지금 회원 가입하시면 즉시 "+ percent +"% 할인 혜택을 드립니다!"
                    ,"지금 회원 가입하고 전용 혜택을 받아보세요!"
                    ,"회원 가입하고 전용 혜택과 맞춤 추천을 받아보세요!"
                ];
                var memberString = memberStringList[Math.floor(Math.random() * memberStringList.length)];

                var loginStringList = [
                    "로그인하고 회원 전용 혜택을 누리세요!"
                    ,"회원 전용 특별 혜택을 위해 로그인하세요!"
                    ,"로그인하고 다양한 혜택을 경험하세요!"
                ];
                var loginString = loginStringList[Math.floor(Math.random() * loginStringList.length)];

                if (result.status === "success") {
                    var loginFlag = result.loginFlag;
                    $box.data("login-flag", loginFlag);

                    var couponYn = result.couponYn;
                    var cpnUrl = "/my-page/coupons";
                    var remainCpnDayYn = result.remainCpnDayYn;

                    var prodRateCpnYn = result.prodRateCpnYn;
                    var prodPriceCpnYn = result.prodPriceCpnYn;
                    var dispoRateCpnYn = result.dispoRateCpnYn;
                    var dispoPriceCpnYn = result.dispoPriceCpnYn;
                    var applyTargetCpnYn = result.applyTargetCpnYn;

                    var signatureYn = result.signatureYn;
                    var remainSigDayYn = result.remainSigDayYn;

                    var weddingAuthYn = result.weddingAuthYn;
                    var remainWedDayYn = result.remainWedDayYn;

                    var memberPartnerGrp = result.memberPartnerGrp;

                    var downTimeYn = result.downTimeYn;

                    //로그인 후 && 쿠폰 및 웨딩인증 관련 메시지 노출
                    //BTOCSITE-67109 마젠토(obs)다운타임 시 영향도 페이지 대응 - downTimeYn 조건 추가
                    if(loginFlag === "Y" && downTimeYn === "N"){
                        $box.data("coupon-yn", couponYn);
                        $box.data("coupon-id", result.couponId);
                        $box.data("signature-yn", signatureYn);
                        $box.data("wed-yn", weddingAuthYn);

                        ////BTOCSITE-58189 복지몰/계열사 재인증.
                        if(memberPartnerGrp === "AFC" || memberPartnerGrp === "AFF"){
                            var reCertifyYn = result.reCertifyYn;
                            if(reCertifyYn == "Y"){
                                var certifyExpireDt = result.certifyExpireDt;
                                var period = result.period;
                                var certifyUrl = "/employee-affiliate/registration";
                                if(memberPartnerGrp === "AFC"){
                                    certifyUrl = "/employee-alliance/registration"
                                }
                                $box.find('a.tit').data("link-url",certifyUrl);
                                $box.find('a.tit').html('임직원 등록 후 '+period+'이 도래하여 재등록 필요<br>(인증만료일 : '+certifyExpireDt+')');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }
                        }

                        if(couponYn === "Y"){
                            if(prodRateCpnYn === "Y" && remainCpnDayYn === "N"){
                                $box.find('a.tit').data("link-url", cpnUrl);
                                $box.find('a.tit').html('LG전자 가전제품 <strong>'+result.prodCpnTitle+'%</strong> 할인 쿠폰이 도착했어요');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }

                            if(prodPriceCpnYn === "Y" && remainCpnDayYn === "N"){
                                $box.find('a.tit').data("link-url", cpnUrl);
                                $box.find('a.tit').html('LG전자 가전제품 할인 쿠폰이 도착했어요');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }

                            if(dispoRateCpnYn === "Y" && remainCpnDayYn === "N"){
                                $box.find('a.tit').data("link-url", cpnUrl);
                                $box.find('a.tit').html('전 소모품 <strong>'+result.dispoCpnTitle+'%</strong> 할인 쿠폰이 도착했어요');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }

                            if(dispoPriceCpnYn === "Y" && remainCpnDayYn === "N"){
                                $box.find('a.tit').data("link-url", cpnUrl);
                                $box.find('a.tit').html('전 소모품 할인 쿠폰이 도착했어요');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }

                            if(applyTargetCpnYn === "Y" && remainCpnDayYn === "N"){
                                $box.find('a.tit').data("link-url", cpnUrl);
                                $box.find('a.tit').html('지난 번에 받으신 쿠폰 잊지 않으셨죠?');
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }

                        }

                        if(weddingAuthYn === "Y" && remainWedDayYn === "N"){
                            $box.find('a.tit').data("link-url", "/benefits/exhibitions/multiple-products");
                            $box.find('a.tit').html('최대 <strong>'+result.mainTitle+'</strong> 혜택 받고 혼수장만하세요');
                            $(window).trigger('openMsgBar');
                            messageBarSendDataLayer();
                            return;
                        }

                        // if(signatureYn === "Y" && remainSigDayYn === "N" && vcui.detect.isMobileDevice){
                        //     $box.find('a.tit').data("link-url", "/support/premium-lounge");
                        //     $box.find('a.tit').html('프리미엄 멤버 전용 라운지를 만나보세요');
                        //     $(window).trigger('openMsgBar');
                        //     messageBarSendDataLayer();
                        //     return;
                        // }

                    }
                    //로그인 전
                    else {
                        var recentLogin = result.recentLogin;
                        $box.data("recent-login", recentLogin);

                        var loginMsgCookie = lgkorUI.getCookie("isLoginMsg") ? lgkorUI.getCookie("isLoginMsg") : "N";
                        var joinMsgCookie = lgkorUI.getCookie("isJoinMsg") ? lgkorUI.getCookie("isJoinMsg") : "N";

                        //모바일 앱
                        if(vcui.detect.isMobileDevice && isApp()){
                            var userId = vcui.Cookie.get('sso_display_user_id');

                            // RECENT_LOGIN 쿠키값이 Y 이고 , sso_display_user_id 값이 없으면 메세지바 노출
                            if(loginMsgCookie === "N" && recentLogin === "Y" && vcui.isEmpty(userId)) {
                                $box.find('a.tit').data("link-url", "/sso/member/login");
                                $box.find('a.tit').html(loginString);
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            } else if(recentLogin === "N" && joinMsgCookie === "N"){
                                $box.find('a.tit').data("link-url", result.joinUrl);
                                $box.find('a.tit').html(memberString);
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }
                        } else {  //모바일 웹, PC
                            if(recentLogin === "Y" && loginMsgCookie === "N"){
                                $box.find('a.tit').data("link-url", "/sso/member/login");
                                $box.find('a.tit').html(loginString);
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;

                            } else if(recentLogin === "N" && joinMsgCookie === "N"){
                                $box.find('a.tit').data("link-url", result.joinUrl);
                                $box.find('a.tit').html(memberString);
                                $(window).trigger('openMsgBar');
                                messageBarSendDataLayer();
                                return;
                            }
                        }
                    }
                }

            }, true, true);
    }

    //메시지 바 클릭 이벤트 처리
    function saveMessageBarData(type){
        var $wrap = $('.messagebar-wrap');
        var $box = $('.messagebar-box');
        var ajaxUrl = $wrap.data("save-url");
        var linkUrl = $box.find('a.tit').data("link-url");
        var linkType;
        var loginFlag = $box.data("login-flag");
        var recentLogin = $box.data("recent-login");

        if(linkUrl && linkUrl.indexOf("benefits") > -1){
            linkType = "wed"
        } else if(linkUrl && linkUrl.indexOf("coupons")> -1){
            linkType = "coupon"
        } else if(linkUrl && linkUrl.indexOf("registration")> -1){
            linkType = "reCert"
        } else {
            linkType = "signature"
        }

        var param = {
            checkType : type
            ,linkType : linkType
            ,couponYn : $box.data("coupon-yn")
            ,couponId : $box.data("coupon-id")
            ,signatureYn : $box.data("signature-yn")
            ,wedYn : $box.data("wed-yn")
        }

        if(loginFlag === "Y"){
            lgkorUI.requestAjaxDataPost(
                ajaxUrl,
                param,
                function(result){
                    if (result.status === "success") {
                        $wrap.removeClass('on');
                        setScrollMsgBar();

                        if(type === "R"){
                            document.location.href = linkUrl;
                        }
                    }

                }, true, true);

        } else {
            if(recentLogin === "Y"){
                lgkorUI.setCookie("isLoginMsg", "Y", false, 7);
            } else {
                lgkorUI.setCookie("isJoinMsg", "Y", false, 7);
            }

            $wrap.removeClass('on');
            setScrollMsgBar();

            if(type === "R"){
                document.location.href = linkUrl;
            }

        }
    }
    
    setSubscriptionEmblemInfo();
    
    // 엠블럼 노출 조회 (임직원/계열사/복지몰은 비노출)
    function setSubscriptionEmblemInfo() {
        var isEmblemDisplay = true;
        if (digitalData && digitalData.userInfo && digitalData.userInfo.partnerType) {
            if (digitalData.userInfo.partnerType == "AFF" || digitalData.userInfo.partnerType == "LGE" || digitalData.userInfo.partnerType == "AFC") {
                isEmblemDisplay = false;
            }
        }
        if (isEmblemDisplay) {
            $("div.middle-badge-list").find('span').show();
        }
    }
    
//BTOCSITE-60575 히어로배너 카운트다운
var isTimerActive = true;
var $clock = $('.main-banner.d-day-type');
var $clockTitle = $clock.find('.counter');
var $day = $clockTitle.find('.day');
var $hour = $clockTitle.find('.hour');
var $min = $clockTitle.find('.min');
var $sec = $clockTitle.find('.sec'); 
function dDayClock() {
    var today = new Date(); 
    //카운터 시작일
    var startDay = today;
    //카운터 마감일
    var targetDay = new Date("2024/03/18 20:00:00");
    if(location.href.indexOf('https://www.lge.co.kr') > -1){
        //운영에만 카운터 시작일 지정  
        startDay = new Date("2024/03/14 00:00:00");
    }
    if((targetDay - today) > 0 && (startDay - today) <= 0){
        $clock.css('display') == 'block' &&  $clock.css('visibility','visible');
        $clock.show();
    }else{
        if(isTimerActive){
            $clock.hide();
            var index = $('.main-banner.d-day-type').parents('.slick-slide').not('.slick-cloned').data('slick-index');
            if(index == undefined || index == 'undefined' ){
                $('.main-banner.d-day-type').parents('.slide-item').remove();
            }else{
                $(".main-banner-swiper .banner__list").slick('slickRemove',index);
            }
        }
        isTimerActive = false;
    }
    var dDay = new Date(targetDay - today); 
    var day = String(Math.floor(dDay / (1000 * 3600 * 24)));  
    var hour = String(Math.floor((dDay / (1000 * 3600)) % 24));
    var minute = String(Math.floor((dDay / (1000 * 60)) % 60));
    var second = String(Math.floor((dDay / 1000) % 60));
    $day.text(pad(day)); 
    $hour.text(pad(hour)); 
    $min.text(pad(minute)); 
    $sec.text(pad(second));
}
setInterval(dDayClock, 1000);
dDayClock();
function pad(pad) {
    if (pad.length == 1){
        return '0'+pad;
    } 
    return pad;
}
////BTOCSITE-60575 히어로배너 카운트다운

    //메시지바 GA 데이터 수집
    function messageBarSendDataLayer() {
        var msg = $('.messagebar-box').find('a.tit').text();

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
                'event': 'customEvent',
                'customEventCategory': '배너노출',
                'customEventAction': '배너노출 - 홈메인 개인화 메시지',
                'customEventLabel': msg.trim()
            });
        }
    }
    //BTOCSITE-48943 [개인맞춤서비스] 홈메인 개인화된 메시지 노출 건

});
